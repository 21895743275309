import React, { useState } from "react";
import Layout from "../components/Layout";
import Table from "../components/Table";
import Modal from "../components/Modal";

const Home = () => {
  const [player, setPlayer] = useState([]);
  const [showModal, setShowModal] = useState(false);

  function toggleModal() {
    setShowModal(!showModal);
  }

  return (
    <Layout>
      <Table setPlayer={setPlayer} toggleModal={toggleModal} />
      <Modal player={player} showModal={showModal} toggleModal={toggleModal} />
    </Layout>
  );
};

export default Home;
