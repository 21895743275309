import React, { useEffect, useState } from "react";

const PlayerCard = (props) => {
  const player = props.player;
  const [imageUrl, setImageUrl] = useState(null);

  async function pullPlayerPhoto(photoId) {
    if (photoId !== null) {
      let imgUrl = await fetch(
        `https://db.bgvolley.dev/Uploads/SimpleFiles/${photoId
          .split("-")
          .join("")}`
      )
        .then((response) => response.blob())
        .then((image) => {
          return URL.createObjectURL(image);
        });

      return imgUrl;
    } else {
      return "https://db.bgvolley.dev/Uploads/SimpleFiles/no-image.png";
    }
  }

  useEffect(() => {
    pullPlayerPhoto(player.photoId).then((url) => {
      setImageUrl(url);
    });
  }, [player]);

  return (
    <div className="rounded-lg bg-white p-6 shadow">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <img
            className="h-48 w-32 rounded"
            src={imageUrl}
            alt={player.firstName}
          />
        </div>
        <div className="ml-4">
          <h3 className="mb-4 font-medium leading-5 text-gray-900 text-lg">
            {player.firstName} {player.lastName}
          </h3>

          <div className="mt-2 flex items-center leading-5 text-gray-500 text-sm">
            <svg
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                clipRule="evenodd"
              />
            </svg>
            СЕК Номер: {player.number}
          </div>
          <div className="mt-2 flex items-center leading-5 text-gray-500 text-sm">
            <svg
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clipRule="evenodd"
              />
            </svg>
            Година на раждане: {player.birthDate.getFullYear()}
          </div>
          <div className="mt-2 flex items-center leading-5 text-gray-500 text-sm">
            <svg
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            Възрастова група: {player.ageGroup}
          </div>
          <div className="mt-2 flex items-center leading-5 text-gray-500 text-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            Състезател от:{" "}
            {player.playingSince ? player.playingSince : "няма информация"}
          </div>
          <div className="mt-2 flex items-center leading-5 text-gray-500 text-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
            Клуб:{" "}
            {player.currentClub
              ? player.currentClub.fullName
              : "няма информация"}
          </div>

          <div className="mt-2 flex items-center leading-5 text-gray-500 text-sm">
            <svg
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                clipRule="evenodd"
              />
            </svg>
            Треньор:{" "}
            {player.currentCoach ? player.currentCoach.name : "няма информация"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerCard;
