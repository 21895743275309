import React from "react";
import img from "../assets/logo-gold.png";

const Header = () => {
  return (
    <header className="my-5 px-4 text-[#333]">
      <img src={img} width={200} alt="bvf logo" className="mx-auto py-3" />
      <h1 className="my-6 text-center text-4xl font-semibold uppercase tracking-wide font-inter">
        Публичен регистър на картотекираните състезaтели
      </h1>
    </header>
  );
};

export default Header;
