import { html, Grid } from "gridjs";
import { useRef, useEffect, useState } from "react";

function Table({ setPlayer, toggleModal }) {
  const wrapperRef = useRef(null);

  useEffect(() => {
    grid.render(wrapperRef.current);

    return () => {
      wrapperRef.current.innerHTML = "";
    };
  }, []);

  const grid = new Grid({
    language: {
      search: {
        placeholder: "🔍 Търсене...",
      },
      pagination: {
        previous: "Предишна",
        next: "Следваща",
        of: "от общо",
        to: "до",
        showing: "😃 Показване от",
        results: () => "състезатели",
      },
    },
    search: true,
    sort: true,
    pagination: {
      enabled: true,
      summary: true,
      resetPageOnUpdate: true,
      limit: 15,
    },
    columns: [
      {
        name: "id",
        hidden: true,
      },
      {
        name: "player",
        hidden: true,
      },
      {
        name: "Име",
        width: "25%",
        attributes: (cell, row) => {
          if (cell) {
            return {
              "data-field": "First Name",
              onclick: () => {
                setPlayer(row.cells[1].data);
                toggleModal();
              },
              style: "cursor: pointer",
            };
          }
        },
        formatter: (cell) =>
          html(
            `<span style="text-transform: capitalize;">${cell.toLocaleLowerCase()}</span>`
          ),
      },
      {
        name: "Фамилия",

        width: "25%",
        attributes: (cell, row) => {
          if (row) {
            return {
              "data-field": "Last Name",
              onclick: () => {
                setPlayer(row.cells[1].data);
                toggleModal();
              },
              style: "cursor: pointer",
            };
          }
        },
        formatter: (cell) =>
          html(
            `<span style="text-transform:capitalize;">${cell.toLocaleLowerCase()}</span>`
          ),
      },
      {
        name: "Година на раждане",
        width: "10%",
        formatter: (cell) =>
          cell.getFullYear() !== 1 ? cell.getFullYear() : "-",
      },
      {
        name: "Възрастова група",
        width: "30px",
        formatter: (cell) => (cell ? cell : "-"),
      },
      {
        name: "Настоящ отбор",

        width: "40%",
        formatter: (cell) => (cell ? cell.fullName : "-"),
      },
    ],
    server: {
      url: "/api/Players",
      then: (data) =>
        data.map((player) => {
          if (player.birthDate) {
            player.birthDate = new Date(player.birthDate);
          }

          return [
            player.id,
            player,
            player.firstName,
            player.lastName,
            player.birthDate,
            player.ageGroup,
            player.currentClub,
          ];
        }),
    },
  });

  return <div ref={wrapperRef} />;
}

export default Table;
